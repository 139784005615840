import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Organization } from "@crispico/foundation-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { PeriodPickerRRC, PeriodPicker } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { dashboardCalculateForRecordsWidgetEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { Datum, DatumValue, Serie } from "@nivo/line";
import gql from "graphql-tag";
import _ from "lodash";
import lodash from 'lodash';
import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Container, Dimmer, Icon, Label, Loader, Segment } from "semantic-ui-react";
import { Filter } from "../CustomQuery/Filter";
import { HistogramPoint, HistogramSerie, HistogramWithDetailsRRC } from "../histogramWithDetails/HistogramWithDetails";
import { ResponsiveLineExt } from "../nivoExt";
import { ResponsiveLineChart } from "../responsiveLineChart/ResponsiveLineChart";
import { MessageExt } from "../semanticUiReactExt";
import { CalculateForRecordsWidgetConfig, COMMA_SEPARATOR, createChartValuesForParentOrganizations, isCompatibleWithDataExplorerFilter, ORGANIZATION } from "./CalculateForRecords";
import { GET_SAVED_DATA } from "./queries";
import { getHistoryGraphColor } from "@crispico/foundation-react/pages/Audit/historyGraphItem/HistoryGraphItem";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

export class CalculateForRecordsChartState extends State {
    data = [] as Array<Serie>;
    dailyHistogramSeries = [] as Array<HistogramSerie>;
    linearHistogramSeries = [] as Array<HistogramSerie>;
    ids = {} as { [key: string]: string };
    details = undefined as Optional<Array<any>>;
    loading = false as boolean;
    widgetId = undefined;
};

export class CalculateForRecordsChartReducers<S extends CalculateForRecordsChartState = CalculateForRecordsChartState> extends Reducers<S> { };

export type CalculateForRecordsChartProps = RRCProps<CalculateForRecordsChartState, CalculateForRecordsChartReducers> & {

    dashboardId: number, widgetIdIfReferenced: string, widgetConfig: CalculateForRecordsWidgetConfig, onlyChart?: boolean, format: string, selectedGroupByKey: string, groupByLabel: React.ReactNode,
    percentageMode: Optional<boolean>, dataExplorerFilter?: Filter, organization: Optional<Organization>
}

export class CalculateForRecordsChart extends React.Component<CalculateForRecordsChartProps, { showDaily: boolean }> {

    protected periodPickerRef = React.createRef<PeriodPicker>();
    entityDescriptor = entityDescriptors[this.props.widgetConfig.entityType];
    protected responsiveLineChartRef = React.createRef<ResponsiveLineChart>();

    constructor(props: CalculateForRecordsChartProps) {
        super(props);
        this.state = { showDaily: false };
    }

    async getEntityMiniFields(entityDescriptor: EntityDescriptor, widgetConfig: CalculateForRecordsWidgetConfig, selectedGroupByKey: string, ids: string) {
        if (Utils.isNullOrEmpty(ids)) {
            this.props.r.setInReduxState({ details: undefined });
            return;
        }

        const loadOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_findByFilter`;
        const query = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) {
                results { ${ID} ${entityDescriptor.getGraphQlFieldsToRequest(entityDescriptor.miniFields)} }
            }
        }`);
        let filter = Filter.create(ID, FilterOperators.forNumber.in, ids);
        const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: FindByFilterParams.create().filter(filter), context: { showSpinner: false } })).data[loadOperationName];
        this.props.r.setInReduxState({ details: result.results, loading: false });
    }

    componentDidMount() {
        this.validateAndGetData();
    }

    componentDidUpdate(prevProps: CalculateForRecordsChartProps, prevState: { showDaily: boolean }) {
        const props = this.props;
        if ((!props.s.data[0]?.data.length || (props.s.data[0].data.length === 1 && props.s.data[0]?.data[0].y === 0)) && (!prevProps || !(prevProps.groupByLabel as any)?.props?.children[0]?.props) && (props.groupByLabel as any)?.props?.children[0]?.props) {
            props.r.setInReduxState({ data: [{ id: "default", data: [{ x: moment().unix() * 1000, y: (props.groupByLabel as any)?.props?.children[0]?.props?.children }] }] });
        } else if (!props.s.data[0]?.data.length) {
            props.r.setInReduxState({ data: [{ id: "default", data: [{ x: moment().unix() * 1000, y: 0 }] }] });
        }

        if (prevState && !prevState.showDaily && this.state.showDaily && !_.isEqual(props.s.data, prevProps.s.data)) {
            this.prepareHistogramData();
        }
    }

    getSelectedGroupByKeyForFilter() {
        if (this.props.dataExplorerFilter && this.props.widgetConfig.groupBySuborganizations && this.props.widgetConfig.groupByFields) {
            const selectedGroupByKeyForFilter: string[] = [];
            this.props.widgetConfig.groupByFields.split(",").forEach(field => {
                const value = getGroupingForField(this.props.dataExplorerFilter!, field)[0];
                if (!value) {
                    return;
                }
                selectedGroupByKeyForFilter.push(value.split("|/|")[0]);
            });
            return selectedGroupByKeyForFilter.join(",");
        }
        return "";
    }

    protected formatY = (datum: DatumValue) => {
        return this.props.percentageMode ? datum.valueOf() + "%" : (this.props.format ? (moment.duration(datum.valueOf(), 'milliseconds') as any).format(this.props.format) : datum.valueOf());
    }

    protected async getData(dashboardId: number, widgetIdIfReferenced: string, calculationLogic: string, selectedGroupByKey: string, currentOrganization: Optional<Organization>) {
        this.props.r.setInReduxState({ loading: true });

        const widgetId = (await apolloClientHolder.apolloClient.query({
            query: gql`
                query dashboardCalculateForRecordsWidgetService_findByFilter($params: FindByFilterParamsInput) {
                    dashboardCalculateForRecordsWidgetService_findByFilter(params: $params) {
                        results {
                            id
                        }
                    }
                }`,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("dashboard.id", FilterOperators.forNumber.equals, dashboardId.toString()),
                Filter.create("widgetIdIfReferenced", FilterOperators.forNumber.equals, widgetIdIfReferenced),
            ])),
            context: { showSpinner: false }
        })).data.dashboardCalculateForRecordsWidgetService_findByFilter.results[0]?.id;
        if (!widgetId || !this.periodPickerRef.current) {
            this.props.r.setInReduxState({ loading: false });
            return;
        }
        this.props.r.setInReduxState({ widgetId: widgetId });

        const params = FindByFilterParams.create().sorts([{ field: "timestamp", direction: "ASC" }])
            .filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("widget.id", FilterOperators.forNumber.equals, widgetId.toString()),
                Filter.create("timestamp", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.periodPickerRef.current?.getStartDate()).toISOString()),
                Filter.create("timestamp", FilterOperators.forDate.lessThanOrEqualTo, moment(this.periodPickerRef.current?.getEndDate()).toISOString())
            ]));

        const result = (await apolloClientHolder.apolloClient.query({ query: GET_SAVED_DATA, variables: params, context: { showSpinner: false } })).data.dashboardCalculateForRecordsValueService_findByFilter

        const data: Datum[] = [];
        const ids: { [key: string]: string } = {};

        const orgId = this.props.organization ? this.props.organization.id : global.currentOrganizationToFilterBy ? global.currentOrganizationToFilterBy.id : currentOrganization?.id;
        const points = createChartValuesForParentOrganizations(result.results, calculationLogic, currentOrganization);
        for (const point of points) {
            const date = moment(point.timestamp * 1000).toDate().getTime();
            let value = 0;
            if (orgId && selectedGroupByKey.length > 0 && point.result[orgId + "," + selectedGroupByKey]) {
                value = point.result[orgId + "," + selectedGroupByKey];
            } else if (orgId && selectedGroupByKey.length === 0) {
                value = point.result[orgId];
            } else if (point.result[selectedGroupByKey]) {
                value = point.result[selectedGroupByKey];
            }

            data.push({ x: date, y: value, records: point.records });
            ids[date] = point.records;
        }

        // WARNING: id = 0 => not accepted any more by nivo
        this.props.r.setInReduxState({ data: [{ id: "default", color: "var(--blue)", data }] as Serie[], ids, loading: false });
        this.prepareHistogramData();
    }

    async prepareHistogramData() {
        if (this.props.s.data.length == 0) {
            return;
        }
        this.props.r.setInReduxState({ loading: true, dailyHistogramSeries: [], linearHistogramSeries: [] })

        // get all records id-s for getting mini fields 
        const allRecords: string[] = [];
        this.props.s.data[0].data.forEach(point => {
            if (!Utils.isNullOrEmpty(point.records)) {
                (point.records as string).split(",").forEach(record => {
                    if (!allRecords.includes(record) && !Utils.isNullOrEmpty(record)) {
                        allRecords.push(record)
                    }
                });
            }
        });

        if (Utils.isNullOrEmpty(allRecords)) {
            this.props.r.setInReduxState({ loading: false });
            return;
        }

        // props.data contains all records without any filter resulting from groups
        // this request should add selected grouping filters widget for getting details about the records
        await this.getEntityMiniFields(this.entityDescriptor, this.props.widgetConfig, this.props.selectedGroupByKey, allRecords.join(','));

        const dailyHistogramSeries: HistogramSerie[] = [];
        let linearHistogramData: HistogramPoint[] = [];
        const dayData: { [key: string]: HistogramPoint[] } = {};
        let id = 0;
        let index = 0;
        const momentNow = moment();
        for (const point of this.props.s.data[0].data) {
            const recordsIdsAndMiniString: { [key: string]: string } = !Utils.isNullOrEmpty(point.records) ? this.getMiniStringForIds(point.records.split(",")) : {};
            // by filtering the data in the method getEntityMiniFields many records will be removed so can happens that no record remains, so nothing to be displayed
            if (Object.keys(recordsIdsAndMiniString).length == 0) {
                continue;
            }
            const day = moment(point.x).format(Utils.dateFormat);
            // new data serie for different day 
            if (!dayData[day]) {
                index = 0;
                dayData[day] = [];
            }

            const element = {
                id: id++,
                index: id - 1,
                x: moment(point.x).valueOf(),
                y: point.y,
                records: Object.values(recordsIdsAndMiniString),
                // this is needed to be able to create links in renderHistogramPoints
                records_ids: Object.keys(recordsIdsAndMiniString),
            }

            // no processing is needed for linear histogram data
            linearHistogramData.push(element);

            // for daily needs to set datum.x date to the current day and keep just hh:mm in order to be displayed all points in one day, label will show original date for each point
            dayData[day].push({ ...element, index: index++, x: momentNow.set({ hour: moment(element.x).hour(), minute: moment(element.x).minute(), second: 0, millisecond: 0, }).valueOf(), x_label: moment(element.x).format(Utils.dateTimeWithSecFormat) })
        }

        // create a serie for each day
        Object.keys(dayData).forEach((key, index) => {
            dailyHistogramSeries.push({ id: key, color: getHistoryGraphColor(index), icon: this.entityDescriptor.icon, data: dayData[key] });
        });

        // start day - end day OR day if just one day OR default if series are empty
        const linearHistogramId = dailyHistogramSeries.length > 0 ? (dailyHistogramSeries.length > 1 ? dailyHistogramSeries[0].id + " - " + dailyHistogramSeries[dailyHistogramSeries.length - 1].id : dailyHistogramSeries[0].id) : "default";
        this.props.r.setInReduxState({ dailyHistogramSeries, linearHistogramSeries: [{ id: linearHistogramId, color: "var(--blue)", data: linearHistogramData }], loading: false })
    }

    private getMiniStringForIds(ids: string[]): { [key: string]: string } {
        const entityType = this.props.widgetConfig.entityType;
        const idsMiniString: { [key: string]: string } = {};
        ids.forEach(id => {
            const entity = this.props.s.details?.find(entity => entity.id.toString() == id);
            if (!Utils.isNullOrEmpty(entity)) {
                idsMiniString[id] = entityDescriptors[entityType].toMiniString(entity);
            }
        });
        return idsMiniString;
    }

    validateAndGetData() {
        if (!this.props.s.loading && (!this.props.dataExplorerFilter || isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter).length === 0)) {
            this.getData(this.props.dashboardId, this.props.widgetIdIfReferenced, this.props.widgetConfig.calculationLogic, this.getSelectedGroupByKeyForFilter(), AppMetaTempGlobals.appMetaInstance.getCurrentOrganization());
        }
    }

    protected renderHistogramPoints(point: HistogramPoint, rowKey: string, rowColor: string): (React.ReactNode | string)[] {
        const rowIds: string[] = point[rowKey + "_ids"];
        if (Utils.isNullOrEmpty(rowIds)) {
            return point[rowKey];
        }
        
        return rowIds.map((id, index) => <NavLink to={entityDescriptors[this.props.widgetConfig.entityType].getEntityEditorUrl(id)}>
            <Label className="small-margin-bottom" basic horizontal color="blue">
                {typeof this.entityDescriptor.icon === 'string' ? <Icon name={this.entityDescriptor.icon} /> : this.entityDescriptor.icon}
                {point[rowKey][index]}
            </Label>
        </NavLink>);
    }

    render() {
        if (this.props.dataExplorerFilter && isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter).length > 0) {
            return <Container fluid>{_msg("CalculateForRecords.error.history")} {isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter!).join(", ")}.</Container>;
        }

        return <div className="flex-container flex-grow wh100"><div className={(this.props.onlyChart ? "CalculateForRecords_hide" : "")}><MessageExt>
            {/* this.props.onlyChart is needed because this RRC is used twice when we enable show chart in widget */}
            <PeriodPickerRRC id={'periodPicker' + this.props.onlyChart + this.props.dashboardId + this.props.widgetIdIfReferenced + this.props.organization?.id} ref={this.periodPickerRef}
                onChange={() => this.validateAndGetData()}
            />
            {this.props.groupByLabel && <Label basic>{this.props.groupByLabel}</Label>}
            <Button className="float-right small-margin-left" size={"small"} as={NavLink} disabled={!this.props.s.widgetId} to={dashboardCalculateForRecordsWidgetEntityDescriptor.getEntityEditorUrl(this.props.s.widgetId)}>{_msg("CalculateForRecords.chart.button")}</Button>
            <Button.Group className="float-right" size={"small"}>
                <Button primary={this.state.showDaily} onClick={() => this.setState({ showDaily: true })}>{_msg("CalculateForRecords.daily.button")}</Button>
                <Button primary={!this.state.showDaily} onClick={() => this.setState({ showDaily: false })}>{_msg("CalculateForRecords.linear.button")}</Button>
            </Button.Group>
        </MessageExt>
        </div>
            {this.props.s.loading === true ? <Dimmer inverted active={this.props.s.loading}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                : this.props.onlyChart ?
                    <ResponsiveLineExt data={this.props.s.data} legends={undefined} margin={{ top: 20, bottom: 20, right: 20, left: 40 }}
                        yFormat={this.formatY} axisLeft={{ format: this.formatY }} curve="stepAfter"
                    /> : <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5 wh100">
                        <HistogramWithDetailsRRC id={'histogramWithDetails' + this.state.showDaily + this.props.dashboardId + this.props.widgetIdIfReferenced + this.props.organization?.id}
                            startDate={this.state.showDaily ? moment().startOf('day').valueOf() : moment(this.periodPickerRef.current?.getStartDate()).valueOf()}
                            endDate={this.state.showDaily ? moment().endOf('day').valueOf() : moment(this.periodPickerRef.current?.getEndDate()).valueOf()}
                            data={this.state.showDaily ? this.props.s.dailyHistogramSeries : this.props.s.linearHistogramSeries}
                            lineCurve={this.state.showDaily ? "linear" : "stepAfter"}
                            axisDateFormat={this.state.showDaily ? Utils.timeFormat : undefined} legendY={entityDescriptors[this.props.widgetConfig.entityType].getLabel(true)}
                            gridOptions={{ rows: [{ key: "records", title: entityDescriptors[this.props.widgetConfig.entityType].getLabel(true), color: "blue" }] }}
                            renderHistogramPointRows={(point, rowKey, rowColor) => this.renderHistogramPoints(point, rowKey, rowColor)}
                        />
                    </div>
            }
        </div >;
    }
}

export const CalculateForRecordsChartRRC = ReduxReusableComponents.connectRRC(CalculateForRecordsChartState, CalculateForRecordsChartReducers, CalculateForRecordsChart);

function getGroupingForField(filter: Filter, field: string) {
    if (!filter.enabled) {
        return [];
    }
    if (filter.field === field) {
        return typeof filter.value === "string" ? [filter.value] : [];
    }
    if (filter.filters) {
        let value: string[] = [];
        filter.filters.forEach(filter => {
            value = value.concat(getGroupingForField(filter, field));
        });
        return value;
    }
    return [];
}"../..""../../apolloClient""../../CompMeta""../periodPicker/PeriodPicker""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/FindByFilterParams""../../FoundationEntityDescriptors""../../reduxReusableComponents/ReduxReusableComponents""../../utils/Utils""../../pages/Audit/historyGraphItem/HistoryGraphItem""../../AppMetaTempGlobals"