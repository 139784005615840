import { createSliceFoundation, EntityEditorPage, getBaseImpures, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { RootReducerForPages } from "@crispico/foundation-react/reduxHelpers/RootReducerForPages";
import { FormikProps } from "formik";
import React from "react";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { userEntityDescriptor } from "../user/userEntityDescriptor";

class UsersFieldDescriptor extends FieldDescriptor {
    constructor() {
        super();
        this.name = "recipientListToUsers";
        this.type = FieldType.oneToMany("RecipientListToUser");
        this.sortable = false;
        this.initialValue = [];
    }

    protected _getLabel = (rootReducerForPages: RootReducerForPages, item: any) => <Label horizontal>{userEntityDescriptor.toMiniString(item)}</Label>;

    renderUsers(props: FieldRendererProps, withLink: boolean = true) {
        return (<RootReducerForPages.Context.Consumer>{rootReducersForPages => props.value && props.value.map((item: any) =>
            withLink ? <NavLink key={item.user?.id} to={userEntityDescriptor.getEntityEditorUrl(item.user?.id)}>{this._getLabel(rootReducersForPages, item.user)}</NavLink> : this._getLabel(rootReducersForPages, item.user))}
        </RootReducerForPages.Context.Consumer>);
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        return this.renderUsers(props);
    }
};

export class RecipientListEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "RecipientList",
            miniFields: ["name"]
        });
    }

    protected customize() {
        this.addTabDescriptor({ oneToManyEntityName: "RecipientListToUser", oneToManyOppositeField: "recipientList" })
            .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
            .addFieldDescriptor({ name: "name", type: FieldType.string })  
            .addFieldDescriptor(new UsersFieldDescriptor())
            .addFieldDescriptor({ name: "additionalEmailAddresses", type: FieldType.string })

        this.infoEditor.wrappedComponentClass = EntityEditorPageRecipientList;
    }
}


class EntityEditorPageRecipientList extends EntityEditorPage<PropsFrom<typeof sliceEntityEditorPageOnlyForExtension>> {
    constructor(props: Readonly<PropsFrom<typeof sliceEntityEditorPageOnlyForExtension>>) {
        super(props);
        this.editorFormSimpleClass = class extends CrudFormInEditor {
            renderFieldEditor(field: string, formikProps: FormikProps<any>): ReactNode {
                if (field === "recipientListToUsers") {
                    return <></>;
                } else {
                    return super.renderFieldEditor(field, formikProps);
                }
            }
        }
    }

    protected async onSaveInternal() {
        const ed = this.props.dispatchers.getSlice().entityDescriptor;
        await this.props.dispatchers.save(this.props.entity, undefined, 
            Object.keys(ed.fields).filter(f => ed.fields[f].enabled && !ed.fields[f].clientOnly && f !== "recipientListToUsers"),
        { componentProps: this.props });
    }
}

export const RecipientListToUserEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "RecipientListToUser",
        miniFields: ["recipientList.name", "user.username", "user.id"]
    })
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "recipientList", type: "RecipientList" })
    .addFieldDescriptor({ name: "user", type: "User" })
);
"../..""../../entity_crud/CrudFormInEditor""../../entity_crud/EntityDescriptor""../../entity_crud/fieldRenderersEditors""../../entity_crud/FieldType""../../reduxHelpers/RootReducerForPages"